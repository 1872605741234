import React from "react";
import Listings from '../components/Listings';

const propertyEstimatesTemplate = props => {
  const { pageContext } = props
  
  pageContext.listings = pageContext.estimates;
  return (
    <Listings listings={pageContext.listings} ctx={pageContext} />
  )
}
export default propertyEstimatesTemplate;
